
import { i18n } from '@/plugins/element'
const t = i18n.global.t
export default {
  // 企业用户详情
  enterprise: [
    {
      // 创建时间
      label: t('table.creation_time'),
      prop: 'created_at'
    },
    { // 所属企业
      label: t('dashboard.enterprise'),
      prop: 'enterprise_name'
    },
    { // 用户登录名
      label: t('user.username'),
      prop: 'name'
    },
    { // 用户昵称
      label: t('user.nick_name'),
      prop: 'nick_name'
    },
    { // 所属销售
      label: t('user.sales'),
      prop: 'sales'
    },
    { // 销售代管
      label: t('user.custody'),
      prop: 'custody'
    },
    { // 联系人
      label: t('user.liaison'),
      prop: 'liaison_man'
    },
    { // 联系人手机号
      label: t('user.liaison_phone'),
      prop: 'liaison_phone'
    },
    { // 联系人邮箱
      label: t('user.liaison_mail'),
      prop: 'liaison_mail'
    },
    { // 备注
      label: t('user.notes'),
      prop: 'comment'
    }
  ],
  // 企业子用户详情
  subuser: [
    { // 注册时间
      label: t('table.registration_time'),
      prop: 'created_at'
    },
    { // 用户登录名
      label: t('user.username'),
      prop: 'name'
    },
    { // 用户昵称
      label: t('user.nick_name'),
      prop: 'nick_name'
    },
    { // 联系人姓名
      label: t('user.liaison_name'),
      prop: 'liaison_man'
    },
    { // 联系人手机号
      label: t('user.liaison_phone'),
      prop: 'liaison_phone'
    },
    { // 联系人邮箱
      label: t('user.liaison_mail'),
      prop: 'liaison_mail'
    },
    { // 共有子用户
      label: t('user.common_sub_user'),
      prop: 'common_sub_user'
    }
  ]
}