<template>
  <div class="main">
    <!-- 搜索栏 -->
    <div class="list-tool-bar">
      <el-button-group class="action">
        <el-button @click="winShow = true" type="primary">{{ t('table.create') }}</el-button>
        <el-button @click="onDelete" type="primary" plain>{{ t('form.delete') }}</el-button>
      </el-button-group>
      <div class="search">
        <div class="search_item">
          <el-input
            v-model="searchData.search"
            @input.native="doSearch"
            :prefix-icon="Search"
            :placeholder="t('user.username')"
            clearable
            class="search-input" />
        </div>
        <div class="search_item search_time">
          <div class="time_select">
            <el-select v-model="timeType">
              <el-option :label="t('table.creation_time')" value="1" />
              <el-option :label="t('table.login_time')" value="2" />
            </el-select>
          </div>
          <!-- 时间选择框 -->
          <custem-time ref="timeRef" @setTime="setTime" />
        </div>
      </div>
    </div>
    <!-- 运营管理 -->
    <custem-table
      ref="table"
      :tableInfo="tableInfo"
      :tableLoading="tableLoading"
      @onSelectData="onSelectData"
      @getList="pageChange"></custem-table>
    <!-- 新增/编辑表单 -->
    <el-dialog
      v-model="winShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      @close="onClose"
      width="900px">
      <create-user
        ref="formRef"
        addType="Subordinate"
        :winShow="winShow"
        @getList="getList"
        @onClose="onClose" />
    </el-dialog>
    <!-- 详情框 -->
    <Detail ref="detailRef" detailType="subuser" />
  </div>
</template>

<script setup>

import Detail from '@/components/UserDetail/index.vue'
import store from '@/store'
import { userManagement } from '@/utils/api'
import { onMounted, reactive, ref, nextTick, inject, computed } from 'vue'
import { ElMessage } from "element-plus"
import { Search } from '@element-plus/icons-vue'

const tableLoading = ref(false)
const winShow = ref(false)
const table = ref()
const t = inject('t')
const formRef = ref() // 表单对象
const delList = ref([]) // 删除数据
const detailRef = ref() // 详情对象
const edit_id = ref(null)
const searchData = ref({
  page: 1,
	filter_role_alias: 'Subordinate'
})

// 弹框标题
const title = computed(() => {
  return !!edit_id.value ? `${t('table.modify')} ${t('role.role5')}` : `${t('table.create')} ${t('role.role5')}`
})

// 删除
const deleteData = async (row) => {
  const { id } = row
  if (!id) return
  await userManagement.roleUserDelete(id)
  ElMessage.success(t('table.del_success'))
  delList.value = []
  tableInfo.data = tableInfo.data.filter((ele) => ele.id !== id)
  getList()
}
// 编辑
const editData = async (row) => {
  const { id } = row
  if (!!!id) return
  edit_id.value = id
  winShow.value = true
  await nextTick()
  formRef.value.getUserDetail(edit_id.value)
}
// 关闭创建弹框
const onClose = () => {
  if (formRef.value) formRef.value.clearForm()
  winShow.value = false
}

// 查看详情
const detailData = async (data) => {
  await nextTick()
  detailRef.value.openWin(data)
}

// 多选删除
const onDelete = async () => {
  if (!!!delList.value.length) {
    ElMessage.warning(t('table.select_del'))
    return
  }
  let arr = []
  delList.value.forEach(item => {
    // 批量提交
    arr.push(
      deleteData(item)
    )
  })
  await nextTick()
  Promise.all(arr).then(async () => {
    ElMessage.success(t('table.del_success'))
  })
}

// 接收多选数据
const onSelectData = (data) => {
  delList.value = data
}

const timeType = ref('1')
const setTime = (timeDate) => {
	if (timeDate.length) {
		if (timeType.value === '1') {
			searchData.value.filter_login_at = ''
			searchData.value.filter_created_at = timeDate[0]
		} else {
			searchData.value.filter_created_at = ''
			searchData.value.filter_login_at = timeDate[0]
		}
		searchData.value.filter_end_at = timeDate[1]
	}
	doSearch()
}

// 搜索事件
const doSearch = () => {
	for (let key in searchData.value) {
		if (!!!searchData.value[key]) delete searchData.value[key]
	}
  searchData.value.page = 1
  getList()
}
// 表格信息
const tableInfo = reactive({
  selection: true, // 选择框列
  header: [
    { // 用户登录名
      key: 'name',
      label: t('user.username'),
      minWidth: '280px'
    },
    { // 用户昵称
      key: 'nick_name',
      label: t('user.nick_name'),
      minWidth: '280px'
    },
    { // 注册日期
      key: 'created_at',
      label: t('table.registration_time'),
      minWidth: '180px',
      sortable: true,
      sortBy: (data) => {
        return data.created_at
      }
    },
    { // 共有子用户
      key: 'visible',
      label: t('user.common_sub_user'),
      minWidth: '160px',
      custem: (data) => {
        return data.visible ? t('table.yes') : t('table.no')
      }
    },
    { // 最后登录
      key: 'login_at',
      label: t('table.last_login_time'),
      sortable: true,
      minWidth: '180px',
      sortBy: (data) => {
        return data.login_at
      }
    },
    {
      key: 'login_at',
      label: t('table.action'),
      width: '180px',
      fixed: 'right',
      handleFun: [
        {
          name: t('table.detail'),
          fn: detailData
        },
        {
          name: t('table.modify'),
          disabled: (data) => {
						if(data.parent_id)
            return data.parent_id !== store.state.users.id
						else return false
          },
          fn: editData
        },
        {
          name: t('form.delete'),
          disabled: (data) => {
            return data.parent_id ? data.parent_id !== store.state.users.id : false
          },
          content: t('form.sure_del'),
          fn: deleteData
        },
      ]
    }
  ]
})

const pageChange = (current) => {
  searchData.value.page = current.page
  getList()
}
// 获取数据列表
const getList = async (search = null) => {
  tableLoading.value = true
  searchData.value = search ? { ...search } : searchData.value
  table.value.resetCurrent(searchData.value.page)
  try {
    const res = await userManagement.roleUserList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>