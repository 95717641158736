<template>
  <el-dialog
    v-model="winShow"
    width="900px"
    :close-on-click-modal="false"
    :title="t('user.enterprise_user_detail')"
    :close-on-press-escape="false"
    @close="winShow = false">
    <custem-detail
      :loading="detailLoading"
      :descriptions="descriptions" />
    <!-- 企业用户详情 -->
    <div v-if="$userRole(['GeneralAdmin']) && detailType === 'enterprise'">
      <div class="tabs">
        <div class="tab select_tab">{{ t('user.sales') }}</div>
      </div>
      <div class="tables">
        <!-- 企业用户所属销售 -->
        <custem-table :tableInfo="tableSalesInfo" :tableLoading="tableLoading" @getList="getEnterpriseUserSales"></custem-table>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, inject, computed } from 'vue'
import { userManagement } from '@/utils/api'
import detail from './detail'

const t = inject('t')
const { proxy } = getCurrentInstance()
const winShow = ref(false)
const detailLoading = ref(false) // 详情loading
const tableLoading = ref(false)
const props = defineProps({
  // 详情类型
  detailType: {
    type: String,
    default: ''
  }
})
// 用户详情字段
const descriptions = computed(() => {
  return detail[props.detailType]
})
// 企业用户所属销售表格字段
const tableSalesInfo = reactive({
  header: [
    { // 销售名称
      key: 'name',
      label: t('user.sales_name'),
      custem: (data) => {
        if (data.sale.name)
          return data.sale.name
        else return '-'
      }
    },
    { // 转入时间
      key: 'start_at',
      minWidth: '180px',
      sortable: true,
      sortBy: (data) => {
        return data.start_at
      },
      label: t('user.entry_time')
    },
    { // 转出时间
      key: 'end_at',
      minWidth: '180px',
      sortable: true,
      sortBy: (data) => {
        return data.end_at
      },
      label: t('user.exit_time')
    }
  ]
})

// 获取企业用户所属销售列表
const getEnterpriseUserSales = async (id) => {
  tableLoading.value = true
  try {
    const res = await userManagement.roleUserSalesList(id)
    tableSalesInfo.data = res.items
    tableSalesInfo.totalPage = res.total
    tableSalesInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 获取企业用户详情接口
const getenterpriseUserDetail = async (id) => {
  detailLoading.value = true
  try {
    const res = await userManagement.roleUserDetail(id)
    descriptions.value.forEach(item => {
      if (item.prop === 'enterprise_name') { // 所属企业
        item.value = res.enterprise && res.enterprise.name || '-'
      } else if (item.prop === 'sales') { // 所属销售
        item.hidden = !proxy.$userRole(['GeneralAdmin'])
        item.value = res.parent && res.parent.name || '-'
      } else if (item.prop === 'custody') { // 销售代管
        item.hidden = !proxy.$userRole(['GeneralAdmin'])
        item.value = res.params && res.params.tutelage ? t('table.no') : t('table.yes')
      } else if (item.prop === 'common_sub_user') { // 共有子用户
        item.value = res.visible ? t('table.yes') : t('table.no')
      } else {
        item.value = res.params[item.prop] || res[item.prop] || '-'
      }
    })
    detailLoading.value = false
    if (proxy.$userRole(['GeneralAdmin']) && props.detailType === 'enterprise') getEnterpriseUserSales(id)
  } catch (error) {
    detailLoading.value = false
  }
}

// 打开弹框
const openWin = async (data) => {
  winShow.value = true
  getenterpriseUserDetail(data.id)
}

defineExpose({
  openWin: openWin
})
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  align-items: center;
  margin: 20px 10px -10px 20px;

  .tab {
    cursor: pointer;
    font-size: 14px;
    margin-right: 20px;

    &:hover {
      opacity: .8;
    }
  }

  .select_tab {
    color: var(--el-color-primary);
  }
}

.tables {
  max-height: 400px;
  overflow-y: scroll;
}
</style>